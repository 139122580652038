/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "why-do-hearing-aids-whistle-and-what-to-do-about-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-hearing-aids-whistle-and-what-to-do-about-it",
    "aria-label": "why do hearing aids whistle and what to do about it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do hearing aids whistle and what to do about it"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you or a loved one wears ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ", at some point they will experience a whistling sound in their hearing aids. This whistling sound is called hearing aid feedback. As you already know, this sound can be very annoying and upsetting, that’s why we have gathered information on the most common causes of hearing aids feedback and what to do to stop it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-the-hearing-aid-feedback",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-the-hearing-aid-feedback",
    "aria-label": "what causes the hearing aid feedback permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What causes the hearing aid feedback?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aid feedback occurs when sound that was supposed to go into your ear canal is picked up by the microphone and amplified again. This can happen if the hearing aids are not fitted properly or if the earmold is not sealing the ear canal properly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here is a more detailed explanation of how hearing aid feedback can occur:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sound waves enter the ear canal and are picked up by the hearing aid microphone."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid amplifies the sound and sends it to the speaker."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The speaker produces the amplified sound, which then travels back into the ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If the earmold is not sealing the ear canal properly, some of the amplified sound can escape through the gap between the earmold and the ear canal."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The microphone picks up the escaping sound and amplifies it again, causing a feedback loop."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "This feedback loop can cause the hearing aids to whistle or produce a high-pitched squealing sound."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "most-common-causes-of-hearing-aids-feedback-and-what-to-do-to-stop-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#most-common-causes-of-hearing-aids-feedback-and-what-to-do-to-stop-it",
    "aria-label": "most common causes of hearing aids feedback and what to do to stop it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Most common causes of hearing aids feedback and what to do to stop it"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several common causes of feedback (also known as whistling or squealing) in hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid microphone is picking up the sound that is being amplified by the hearing aid speaker. This can be caused by the hearing aid not being properly fitted or positioned in the ear. To fix this, try adjusting the fit of the hearing aid or repositioning it in the ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid volume is set too high. Feedback can occur when the volume is turned up too high, especially in noisy environments. Try turning the volume down to see if that stops the feedback."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid is blocked by earwax or debris. If there is something blocking the microphone or speaker of the hearing aid, it can cause feedback. Clean the hearing aid regularly to prevent this."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid is damaged. If the hearing aid has been dropped or has sustained some other type of damage, it could be causing the feedback. If you suspect that the hearing aid is damaged, bring it to a hearing care professional for repair."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The hearing aid is not compatible with a particular device. Some devices, such as phones or laptops, can emit electromagnetic fields that can cause feedback in hearing aids. If you are experiencing feedback when using a particular device, try moving the device further away from the hearing aid or using a different device."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-your-hearing-aids-checked-by-a-qualified-audiologist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-your-hearing-aids-checked-by-a-qualified-audiologist",
    "aria-label": "get your hearing aids checked by a qualified audiologist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get your hearing aids checked by a qualified Audiologist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are unable to identify the cause of the hearing aids feedback and are unable to stop it on your own, it is a good idea to consult with your hearing care professional for assistance. We will be able to diagnose the issue and recommend a solution."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact Contact a qualified Audiologist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
